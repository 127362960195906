@import './../scssVariables/variables.module.scss';

.trexTextAndMatch-wrapper {
    box-sizing: border-box;
}

.layout-pane {
    overflow: hidden !important;
    height: 100%;
    min-height: 150px;
}

.trexParentPage-content {
    position: relative;
    display: grid;
    grid-template-rows: 64px 100px auto;
}

.TrexMainPage-splitterWrapperDiv {
    height: auto;
}

.splitter-layout {
    position: relative !important;
}

.TrexMainPage-splitterWrapper {
    .layout-splitter {
        @include theme-aware-important('background-color', $textColorOnSecondary);
        transition: background-color 0.2s;
        height: 3px !important;
    }
}
.splitter-layout {
    position: inherit !important;
    height: 100%;

    .splitter-layout > .layout-splitter {
        @include theme-aware-important('background-color', $textColorOnSecondary);
        transition: background-color 0.2s;
        width: 3px !important;
        height: 100% !important;
    }

    .splitter-layout .layout-splitter:hover {
        @include theme-aware-important('background-color', $textColorOnSecondary);
    }
}

// Responsive mobile behaviour

.trexParentPage-content-extended {
    .layout-pane {
        width: calc(100vw - 350px);
    }
}

@media handheld, screen and (max-width: 610px) {
    .trexParentPage-content-extended {
        .TrexMainPage-splitterWrapperDiv {
            overflow-y: hidden;
        }
    }
}

@media screen and (min-height: 720px) {
    .TrexMainPage-splitterWrapperDiv {
        overflow-y: hidden;
    }
}
