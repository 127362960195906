html {
    font-size: 100%;
}

@font-face { font-family: Roboto; src: url('./fonts/roboto/Roboto-Regular.ttf'); }
@font-face { font-family: 'Roboto Mono'; src: url('./fonts/roboto/RobotoMono-Regular.ttf'); }

body {
    margin: 0;
    font-family: Roboto, 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
