@import './../../scssVariables/variables.module.scss';

.trexSettings-wrapper {
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    white-space: nowrap;
    display: block;

    & > * {
        margin-bottom: 10px;
    }

    a:link {
        @include theme-aware('color', $primaryColorDark);
        transition: color 0.2s;
    }
    a:visited {
        @include theme-aware('color', $primaryColorDark);
        transition: color 0.2s;
    }
    .trexSettings-spaceHolder {
        height: 20px;
    }

    .trexSettings-row {
        display: grid;
        grid-template-columns: auto auto min-content;
    }
    .trexSettings-row-devMode {
        display: block;
        width: 90%;
        position: absolute;
        bottom: 0;

        .trexSettings-row-devModeGrid {
            display: grid;
            grid-template-columns: auto auto min-content;
            margin-bottom: 20px;
        }
    }

    .trexSettings-devLink {
        position: absolute;
        bottom: 0;
        margin-bottom: 20px;
    }

    .MuiSwitch-root {
        grid-column: 3;
    }

    .trexSettings-label {
        margin: auto 0;
        padding-right: 5vw;
        transition: 0.2s;
    }

    .trexDropdown-select {
        grid-column: 3;
        width: 80px;

        .MuiSelect-select:focus {
            @include theme-aware-important('background-color', $secondaryColorLight);
            transition: 0.2s;
        }
    }
}

.trexSettings-Switch {
    @include theme-aware-important('color', $primaryColorDark);
}
.TrexSettings-cookiesText {
    @include theme-aware-important('color', $textColorOnSecondary);
    transition: 0.2s;
    font-size: 12px;
    white-space: normal;
}
