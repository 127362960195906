@import '../../../scssVariables/variables.module.scss';

.trexAnchor-wrapper {
    border: 3px solid purple;
    border-radius: 50px;
    padding: 10px;
    background-color: rgba(128, 0, 128, 0.3);
    height: 100%;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    display: flex;
}

.trexAnchor-content {
    text-align: center;
    transition: 0.2s;
}
