@import '../../../scssVariables/variables.module.scss';

.trexStartNode-sourcehandle {
    height: 30px !important;
    width: 30px !important;
    border: 6px solid !important;
    @include theme-aware-important('border-color', $primaryColor);
    transition: border-color 0.2s;
    background-color: transparent !important;
}

.trexStartNode-wrapper {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
}
