@import '../../scssVariables/variables.module.scss';
@import '../../../../node_modules/codemirror/lib/codemirror.css';

.trexTextInput-header {
    @include theme-aware('background-color', $secondaryColor);
    @include theme-aware('color', $textColorOnSecondary);
    transition: background-color 0.2s, color 0.2s;

    text-align: left;
    font-size: 1.6em;
    padding: 0.2em 30px;
}

.trexTextInput-areaWrapper {
    box-sizing: border-box;
    height: calc(100% - 41px);
    .CodeMirror-line::after {
        pointer-events: none;
        color: rgba(165, 165, 165, 0.44);
        content: '¬';
        padding-left: 2px;
    }
    .CodeMirror-wrap pre.CodeMirror-line,
    .CodeMirror-wrap pre.CodeMirror-line-like {
        word-break: break-word;
    }

    .CodeMirror {
        height: 100%;
    }

    .CodeMirror-scroll {
        @include theme-aware('color', $textColorOnSecondary);
        @include theme-aware('background-color', $backgroundColor);
        transition: background-color 0.2s, color 0.2s;
        padding-right: 10px;
        cursor: text;

        .CodeMirror-cursor {
            @include theme-aware-important('border-left-color', $textColorOnSecondary);
        }
    }
}

.trexTextInput-wrapper {
    height: 100%;
}

.trexTextInput-textarea {
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    height: 100%;

    resize: none;

    font-size: 1.4em;
    @include theme-aware('color', $textColorOnSecondary);
    @include theme-aware('background-color', $backgroundColor);
    transition: background-color 0.2s, color 0.2s;

    border: 5px solid transparent;
    border-left: 30px solid transparent;
    outline: 0;
}
