@import './../../scssVariables/variables.module.scss';

.TrexShare-wrapper {
    border: 0;
    height: calc(100vh - 65px);
    overflow-y: auto;
    @include theme-aware('background-color', $secondaryColorLight);
    @include theme-aware('color', $textColorOnSecondary);
    transition: background-color 0.2s, color 0.2s;

    .TrexShare-shareMessage {
        @include theme-aware('background-color', $secondaryColor);
        transition: background-color 0.2s;
        width: 100%;
        display: inline-block;
        font-weight: bold;
        padding: 8px 0 8px 8px;
        box-sizing: border-box;
    }

    .TrexShare-linkText {
        margin-top: 8px;
        margin-bottom: 8px;
        word-wrap: break-word;
        max-height: 60px;
        overflow-y: auto;
        padding-left: 8px;
        padding-right: 8px;
    }

    .TrexShare-linkTextOpened {
        margin-top: 8px;
        margin-bottom: 8px;
        word-wrap: break-word;
        max-height: calc(100% - 160px);
        overflow-y: auto;
        padding-left: 8px;
    }

    .TrexShare-openLinkwrapper {
        @include theme-aware('background-color', $secondaryColor);
        transition: background-color 0.2s;
        display: grid;
        margin: 0 auto 12px;
        cursor: pointer;

        .TrexShare-Icon {
            margin: 0 auto;
        }
    }

    .TrexShare-IconButtonWrapper {
        width: 100%;
        align-items: center;
        display: grid;
    }
}
