@import '../../../scssVariables/variables.module.scss';

.trexCharacterClass-wrapper {
    border: 3px solid #ff9900;
    padding: 0 10px;
    box-sizing: content-box;
    background-color: rgba(255, 153, 0, 0.33);
}

.trexCharacterClass-content {
    text-align: center;
    padding-bottom: 15px;
    transition: 0.2s;
}

.trexCharacterClass-legend {
    font-weight: bold;
    margin-top: -8px;
    color: #ff9900;
}
