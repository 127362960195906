@import './../../scssVariables/variables.module.scss';

.trexCheatSheet-wrapper {
    height: calc(100vh - 65px);
    overflow-y: auto;

    .trexCheatSheet-categoryTitle {
        @include theme-aware('background-color', $secondaryColor);
        @include theme-aware('color', $textColorOnSecondary);
        transition: background-color 0.2s, color 0.2s;
        font-weight: bold;
        margin-bottom: 4px;
        padding: 8px 0 8px 15px;
    }

    .trexCheatSheet-entryWrapper {
        @include theme-aware('color', $textColorOnSecondary);
        transition: color 0.2s;
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 10px;
        padding-bottom: 10px;
        white-space: nowrap;

        .trexCheatSheet-entryExample {
            width: fit-content;
            margin-left: 16px;
        }

        .trexCheatSheet-entryDescription {
            width: fit-content;
        }
    }
}
