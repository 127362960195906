@import '../../scssVariables/variables.module.scss';

.TrexDiagramOutputModal-modal {
    @include theme-aware('color', $textColorOnSecondary);
    display: flex;
    transition: color 0.2s;
    align-items: center;
    margin: auto;
    overflow-wrap: break-word;
    height: 90vh;
    width: 90vw;

    .TrexDiagramOutputModal-wrapper {
        height: 100%;
        display: grid;
        grid-template-rows: min-content auto;
        @include theme-aware('background-color', $backgroundColor);
        border-radius: 10px;
        outline: none;
        text-align: center;
        padding-bottom: 15px;

        .TrexDiagramOutputModal-titlebar {
            height: 100%;
            display: grid;
            grid-template-columns: auto min-content;
            margin: 0 10px;

            :hover {
                cursor: default;
            }

            .trexIconButton-button-modal {
                padding: 4px;
                :hover {
                    cursor: pointer;
                }
            }

            .ReactCodeMirror {
                overflow: hidden;
                padding-top: 6px;

                .CodeMirror-cursors {
                    visibility: hidden;
                }
            }
        }

        .react-flow {
            border-top: 2px solid;
            @include theme-aware('border-color', $secondaryColor);
        }

        .trexDiagramOutput-wrapper {
            width: 90vw;
        }
    }
    .CodeMirror-scroll {
        margin-bottom: 0;
        overflow-y: hidden !important;
        overflow-x: hidden !important;
    }

    .CodeMirror {
        @include theme-aware('color', $textColorOnSecondary);
        @include theme-aware('background-color', $backgroundColor);
        font-size: 1.6em;
        max-height: 30px;
    }
}
