@import '../../../scssVariables/variables.module.scss';

.trexGroup-wrapper0 {
    border: 3px dashed $groupHighlightText0;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.trexGroup-wrapper1 {
    border: 3px dashed $groupHighlightText1;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.trexGroup-wrapper2 {
    border: 3px dashed $groupHighlightText2;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.trexGroup-wrapper3 {
    border: 3px dashed $groupHighlightText3;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.trexGroup-wrapper4 {
    border: 3px dashed $groupHighlightText4;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.trexGroup-legend0 {
    font-weight: bold;
    color: $groupHighlightText0;
}

.trexGroup-legend1 {
    font-weight: bold;
    color: $groupHighlightText1;
}

.trexGroup-legend2 {
    font-weight: bold;
    color: $groupHighlightText2;
}

.trexGroup-legend3 {
    font-weight: bold;
    color: $groupHighlightText3;
}

.trexGroup-legend4 {
    font-weight: bold;
    color: $groupHighlightText4;
}

.trexGroup-handle {
    background-color: transparent !important;
    border: none !important;
}
