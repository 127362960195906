@import '../../../scssVariables/variables.module.scss';

.trexAnyChar-wrapper {
    border: 3px solid #ff9900;
    background-color: rgba(255, 153, 0, 0.33);
    padding: 10px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.trexAnyChar-content {
    text-align: center;
    transition: 0.2s;
}

.trexAnyChar-legend {
    font-weight: bold;
    color: #ff9900;
}
