@import './../../scssVariables/variables.module.scss';

.trexHeader-wrapper {
    height: 64px;
    @include theme-aware('background-color', $primaryColor);
    display: grid;
    grid-template-columns: min-content auto min-content;
    padding-left: 20px;
    padding-right: 30px;
    white-space: nowrap;
    transition: background-color 0.2s;

    .gitlabIcon-wrapper {
        margin: auto 0;
        grid-column: 3;
        text-decoration: none;
        display: grid;
        grid-template-columns: min-content min-content;
        gap: 8px;
        .trexHeader-gitlab {
            @include theme-aware('color', $textColorOnPrimary);
            transition: color 0.2s;
            font-size: 1em;
            margin: auto 0;
        }
        .gitlabIcon {
            @include theme-aware('color', $textColorOnPrimary);
            transition: color 0.2s;
            margin: auto 0;
        }
    }

    .trexHeader-title {
        text-decoration: none;
        @include theme-aware('color', $textColorOnPrimary);
        transition: color 0.2s;
        margin: auto 0;
        font-size: 2em;
        padding-bottom: 10px;
        .trexHeader-logo {
            width: 48px;
            height: 48px;
            margin-right: 8px;
            margin-bottom: -4px;
            position: relative;
            top: 8px;
        }
    }
}

@media handheld, screen and (max-width: 799px) {
    .trexHeader-logo {
        display: none;
    }
    .gitlabIcon-wrapper svg {
        display: none;
    }
}

@media handheld, screen and (max-width: 610px) {
    .trexHeader-wrapper {
        margin-left: 64px;
    }
}
