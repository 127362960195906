$primaryColor: 'primaryColor';
$primaryColorLight: 'primaryColorLight';
$primaryColorDark: 'primaryColorDark';
$secondaryColor: 'secondaryColor';
$secondaryColorLight: 'secondaryColorLight';
$secondaryColorDark: 'secondaryColorDark';
$textColorOnPrimary: 'textColorOnPrimary';
$textColorOnSecondary: 'textColorOnSecondary';
$textColorOnSecondaryLight: 'textColorOnSecondaryLight';
$hoverIconColor: 'hoverIconColor';
$backgroundColor: 'backgroundColor';
$lightTextColor: 'lightTextColor';

$groupHighlightText0: rgb(222, 119, 253);
$groupHighlight0: rgba(222, 119, 253, 0.33);
$groupHighlightText1: rgb(96, 252, 65);
$groupHighlight1: rgba(96, 252, 65, 0.33);
$groupHighlightText2: rgb(233, 30, 98);
$groupHighlight2: rgba(233, 30, 98, 0.3);
$groupHighlightText3: rgb(130, 197, 252);
$groupHighlight3: rgba(130, 197, 252, 0.33);
$groupHighlightText4: rgb(240, 220, 40);
$groupHighlight4: rgba(255, 235, 59, 0.33);

$themes: (
    lightTwitch: (
        theme: 'light',
        primaryColor: #6d49cb,
        primaryColorLight: #9268f5,
        primaryColorDark: #6d49cb,
        secondaryColor: #dbdbdb,
        secondaryColorLight: #ececec,
        secondaryColorDark: #000000,
        textColorOnPrimary: #ffffff,
        textColorOnSecondary: #4e4e4e,
        textColorOnSecondaryLight: #929292,
        hoverIconColor: #000000,
        backgroundColor: #ffffff,
    ),
    darkTwitch: (
        theme: 'dark',
        primaryColor: #54369e,
        primaryColorLight: #6d49cb,
        primaryColorDark: #925bff,
        secondaryColor: #404040,
        secondaryColorLight: #343434,
        secondaryColorDark: #000000,
        textColorOnPrimary: #ffffff,
        textColorOnSecondary: #ffffff,
        textColorOnSecondaryLight: #aaaaaa,
        hoverIconColor: #ffffff,
        backgroundColor: #555555,
    ),
    lightFrog: (
        theme: 'light',
        primaryColor: #546d5a,
        primaryColorLight: #819b87,
        primaryColorDark: #546d5b,
        secondaryColor: #dbdbdb,
        secondaryColorLight: #ececec,
        secondaryColorDark: #000000,
        textColorOnPrimary: #ffffff,
        textColorOnSecondary: #4e4e4e,
        textColorOnSecondaryLight: #929292,
        hoverIconColor: #000000,
        backgroundColor: #ffffff,
    ),
    darkFrog: (
        theme: 'dark',
        primaryColor: #546d5a,
        primaryColorLight: #819b87,
        primaryColorDark: #74967d,
        secondaryColor: #404040,
        secondaryColorLight: #343434,
        secondaryColorDark: #000000,
        textColorOnPrimary: #ffffff,
        textColorOnSecondary: #ffffff,
        textColorOnSecondaryLight: #aaaaaa,
        hoverIconColor: #ffffff,
        backgroundColor: #555555,
    ),
    lightTelekom: (
        theme: 'light',
        primaryColor: #e032a6,
        primaryColorLight: #c7187e,
        primaryColorDark: #e032a5,
        secondaryColor: #dbdbdb,
        secondaryColorLight: #ececec,
        secondaryColorDark: #000000,
        textColorOnPrimary: #ffffff,
        textColorOnSecondary: #4e4e4e,
        textColorOnSecondaryLight: #929292,
        hoverIconColor: #000000,
        backgroundColor: #ffffff,
    ),
    darkTelekom: (
        theme: 'dark',
        primaryColor: #e032a6,
        primaryColorLight: #ff6cd8,
        primaryColorDark: #e032a5,
        secondaryColor: #404040,
        secondaryColorLight: #343434,
        secondaryColorDark: #000000,
        textColorOnPrimary: #ffffff,
        textColorOnSecondary: #ffffff,
        textColorOnSecondaryLight: #aaaaaa,
        hoverIconColor: #ffffff,
        backgroundColor: #555555,
    ),
    lightMclaren: (
        theme: 'light',
        primaryColor: #fb8122,
        primaryColorLight: #ffb254,
        primaryColorDark: #fb8122,
        secondaryColor: #dbdbdb,
        secondaryColorLight: #ececec,
        secondaryColorDark: #000000,
        textColorOnPrimary: #ffffff,
        textColorOnSecondary: #4e4e4e,
        textColorOnSecondaryLight: #929292,
        hoverIconColor: #000000,
        backgroundColor: #ffffff,
    ),
    darkMclaren: (
        theme: 'dark',
        primaryColor: #c25200,
        primaryColorLight: #fb8122,
        primaryColorDark: #c25200,
        secondaryColor: #1d2228,
        secondaryColorLight: #1d2228,
        secondaryColorDark: #000000,
        textColorOnPrimary: #ffffff,
        textColorOnSecondary: #ffffff,
        textColorOnSecondaryLight: #aaaaaa,
        hoverIconColor: #ffffff,
        backgroundColor: #444950,
    ),
    lightWine: (
        theme: 'light',
        primaryColor: #c3073f,
        primaryColorLight: #fc5069,
        primaryColorDark: #c3073f,
        secondaryColor: #dbdbdb,
        secondaryColorLight: #ececec,
        secondaryColorDark: #000000,
        textColorOnPrimary: #ffffff,
        textColorOnSecondary: #4e4e4e,
        textColorOnSecondaryLight: #929292,
        hoverIconColor: #000000,
        backgroundColor: #ffffff,
    ),
    darkWine: (
        theme: 'dark',
        primaryColor: #8b001a,
        primaryColorLight: #c24041,
        primaryColorDark: #c24041,
        secondaryColor: #404040,
        secondaryColorLight: #343434,
        secondaryColorDark: #000000,
        textColorOnPrimary: #ffffff,
        textColorOnSecondary: #ffffff,
        textColorOnSecondaryLight: #aaaaaa,
        hoverIconColor: #ffffff,
        backgroundColor: #555555,
    ),
    frohesSchaffen: (
        theme: 'dark',
        primaryColor: #000000,
        primaryColorLight: #0c0c0c,
        primaryColorDark: #00ff00,
        secondaryColor: #0c0c0c,
        secondaryColorLight: #0c0c0c,
        secondaryColorDark: #000000,
        textColorOnPrimary: #00ff00,
        textColorOnSecondary: #00ff00,
        textColorOnSecondaryLight: #009000,
        hoverIconColor: #16c60c,
        backgroundColor: #000000,
    ),
);

/**
 * theme-aware - Change color of a css property based on the currently active theme
 *
 * @param  {key}     CSS property
 * @param  {color}   Color name defined in the themes under _color-palette.scss
 *
 * @example - @include theme-aware('background', 'color-background');
 * @returns - background: #FFF;
 */
@mixin theme-aware($key, $color) {
    @each $theme-name, $theme-color in $themes {
        .theme-#{$theme-name} & {
            #{$key}: map-get(map-get($themes, $theme-name), $color);
        }
    }
}

/**
 * theme-aware - Change color of a css property based on the currently active theme and sets it to be important
 *
 * @param  {key}     CSS property
 * @param  {color}   Color name defined in the themes under _color-palette.scss
 *
 * @example - @include theme-aware('background', 'color-background');
 * @returns - background: #FFF;
 */
@mixin theme-aware-important($key, $color) {
    @each $theme-name, $theme-color in $themes {
        .theme-#{$theme-name} & {
            #{$key}: map-get(map-get($themes, $theme-name), $color) !important;
        }
    }
}

/**
 * Converts a SASS map of themes and into CSS output.
 *
 * creates for all themes variables in the format {themename}-{propertyname}
 * e.g. twitch-primaryColor
 *
 * @param  {map} $map the map of properties to output
 */
@mixin map-to-props($map, $root) {
    @if type-of($map) == map {
        @each $prop, $value in $map {
            @if type-of($value) != map {
                #{$root}-#{inspect($prop)}: #{inspect($value)};
            } @else {
                @include map-to-props($value, $prop);
            }
        }
    }
}

:export {
    @include map-to-props($themes, '');
}
