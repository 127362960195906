@import '../../../scssVariables/variables.module.scss';

.CodeMirrorHighlighting-matchHighlighted0 {
    background-color: rgba(0, 255, 242, 0.5);
}

.CodeMirrorHighlighting-matchHighlighted1 {
    background-color: rgba(0, 173, 236, 0.5);
}

.CodeMirrorHighlighting-groupHighlighted0 {
    background: $groupHighlight0;
}

.CodeMirrorHighlighting-groupHighlighted1 {
    background: $groupHighlight1;
}

.CodeMirrorHighlighting-groupHighlighted2 {
    background: $groupHighlight2;
}

.CodeMirrorHighlighting-groupHighlighted3 {
    background: $groupHighlight3;
}

.CodeMirrorHighlighting-groupHighlighted4 {
    background: $groupHighlight4;
}

.CodeMirrorHighlighting-otherToken {
    color: rgb(91, 181, 255);
    font-weight: bold;
}

.CodeMirrorHighlighting-characterSet {
    background: rgba(255, 153, 0, 0.33);
}

.CodeMirrorHighlighting-error {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAACCAYAAAB/qH1jAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3QUXCToH00Y1UgAAACFJREFUCNdjPMDBUc/AwNDAAAFMTAwMDA0OP34wQgX/AQBYgwYEx4f9lQAAAABJRU5ErkJggg==);
    background-position: bottom left;
    background-repeat: repeat-x;
}