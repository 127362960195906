@import './../../scssVariables/variables.module.scss';

.trexIconButton-button {
    @include theme-aware('color', $textColorOnSecondary);
    @include theme-aware('background-color', $secondaryColorLight);
    border: none;
    outline: none;
    padding: 16px;
    height: 64px;
    width: 64px;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
        transition: all 0.2s;
        @include theme-aware('background-color', $secondaryColor);
        @include theme-aware('color', $hoverIconColor);
    }
}

.trexIconButton-button-title {
    display: grid;
    grid-template-columns: auto auto;
    @include theme-aware('background-color', $primaryColor);
    @include theme-aware('color', $textColorOnPrimary);
    border: none;
    border-radius: 4px;
    outline: none;
    padding: 4px 8px;
    height: fit-content;
    width: fit-content;
    margin: 0 auto;
    cursor: pointer;
    transition: all 0.2s;
    gap: 8px;

    .trexIconButton-title {
        font-size: 1em;
        padding-top: 1px;
    }

    &:hover {
        transition: all 0.2s;
        @include theme-aware('background-color', 'primaryColorLight');
        @include theme-aware('color', $textColorOnPrimary);
    }
}

.trexIconButton-button-secondary {
    @include theme-aware('background-color', $secondaryColor);
    @include theme-aware('color', $textColorOnSecondary);
    transition: background-color 0.2s, color 0.2s;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;

    &:hover {
        transition: all 0.2s;
        @include theme-aware('color', $hoverIconColor);
    }
}

.trexIconButton-button-tertiary {
    @include theme-aware('background-color', $backgroundColor);
    @include theme-aware('color', $textColorOnSecondary);
    transition: background-color 0.2s, color 0.2s;
    outline: none;
    border: none;
    border-radius: 4px;
    padding: 0px 6px;
    margin-top: 6px;
    margin-left: 8px;
    margin-right: 8px;
    cursor: pointer;

    &:hover {
        transition: all 0.2s;
        @include theme-aware('color', $hoverIconColor);
    }
}

.trexIconButton-button-fullScreenButton {
    @include theme-aware('background-color', $secondaryColor);
    @include theme-aware('color', $textColorOnSecondary);
    transition: background-color 0.2s, color 0.2s;
    outline: none;
    border: none;
    padding: 0;
    float: right;
    margin-right: -10px;
    margin-top: 4px;
    cursor: pointer;

    &:hover {
        transition: all 0.2s;
        @include theme-aware('color', $hoverIconColor);
    }
}

.trexIconButton-button-modal {
    @include theme-aware('background-color', $backgroundColor);
    @include theme-aware('color', $textColorOnSecondary);
    transition: background-color 0.2s, color 0.2s;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;

    &:hover {
        transition: all 0.2s;
        @include theme-aware('color', $hoverIconColor);
    }
}

.trexIconButton-button-mobile {
    @include theme-aware('color', $textColorOnSecondary);
    @include theme-aware('background-color', $secondaryColorLight);
    border: none;
    outline: none;
    padding: 16px;
    height: 64px;
    width: 64px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
        transition: all 0.2s;
        @include theme-aware('background-color', $secondaryColor);
        @include theme-aware('color', $hoverIconColor);
    }
}

@media handheld, screen and (max-width: 610px) {
    .trexIconButton-button-mobile {
        @include theme-aware('color', $textColorOnPrimary);
        @include theme-aware('background-color', $primaryColor);
        transition: none;
        &:hover {
            @include theme-aware('background-color', $primaryColor);
            @include theme-aware('color', $textColorOnPrimary);
        }
    }
}
