@import '../scssVariables/variables.module.scss';

.trexParentPage-content {
    height: 100vh;
}

@media screen and (min-width: 611px) {
    .trexParentPage-content-extended {
        margin-left: 350px;
    }
    .trexParentPage-content-collapsed {
        margin-left: 64px;
    }
}

@media handheld, screen and (max-width: 610px) {
    .trexParentPage-content-extended {
        position: absolute;
    }
}

#themedBody {
    @include theme-aware('background-color', $backgroundColor);
    transition: background-color 0.2s;
    transition: 0.2s;
}
