@import '../../scssVariables/variables.module.scss';

.trexDropdown-select {
    border: none !important;
    outline: none !important;
    @include theme-aware-important('color', $textColorOnSecondary);
    transition: 0.2s;

    .MuiSelect-select:focus {
        @include theme-aware-important('background-color', $secondaryColor);
    }
    .MuiSelect-icon {
        @include theme-aware-important('color', $textColorOnSecondary);
        transition: 0.2s;
    }
}
