@import '../../../scssVariables/variables.module.scss';

.react-flow__edge-path {
    stroke-width: 3 !important;
}

.react-flow__edge-path-repetition {
    stroke-width: 3 !important;
    stroke: #b1b1b7;
    stroke-dasharray: 20;
    animation: dashdraw 10s linear infinite !important;
    -webkit-animation: dashdraw 10s linear infinite !important;
}

.react-flow__edge-textbg {
    @include theme-aware('fill', $backgroundColor);
    transition: fill 0.2s;
    padding: 2px;
}

.react-flow__edge-text {
    @include theme-aware('fill', $textColorOnSecondary);
    transition: fill 0.2s;
}
