@import './../../scssVariables/variables.module.scss';

.trexSideBarItem-wrapper {
    padding-top: 5px;
    padding-bottom: 5px;
    display: grid;
    grid-template-columns: min-content auto min-content;
}

.trexSideBarItem-wrapper:hover {
    @include theme-aware('background-color', $secondaryColor);
    cursor: pointer;
    transition: all 0.2s;

    .trexSideBarItem-text {
        @include theme-aware('color', $hoverIconColor);
    }

    .trexSideBarItem-icon {
        @include theme-aware('color', $hoverIconColor);
    }
}

.trexSideBarItem-icon {
    padding: 10px 16px;
    border: none;
    cursor: pointer;
}

.trexSideBarItem-text {
    white-space: nowrap;
    font-size: 19px;
    padding: 10px;
    padding-top: 11px;
    line-height: 27px;
}
