@import '../../../scssVariables/variables.module.scss';

.trexInvisibleNode-wrapper {
    border: 3px solid transparent;
    padding: 10px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.trexInvisibleNode-content {
    text-align: center;
}

.trexInvisibleNode-targetHandle, .trexInvisibleNode-sourceHandle {
    background-color: transparent !important;
    border: none !important;
}