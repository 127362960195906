@import '../../scssVariables/variables.module.scss';
@import '../../../../node_modules/codemirror/lib/codemirror.css';
@import 'codemirror/codemirrorHighlighting';

.trexRegExInput-wrapper {
    box-sizing: border-box;

    .TrexShare-IconButtonWrapper {
        margin-left: -14px;
    }
}

.trexRegExInput-header {
    @include theme-aware('background-color', $secondaryColor);
    @include theme-aware('color', $textColorOnSecondary);
    transition: background-color 0.2s, color 0.2s;

    display: grid;
    grid-template-columns: auto auto min-content min-content;

    text-align: left;
    font-size: 1.6em;
    padding: 0.2em 9px 0.2em 30px;

    .trexDropdown-select {
        grid-column: 3;
        margin-right: 10px;
    }

    .trexRegExInput-button-help {
        display: flex;
        grid-column: 4;
        align-items: center;
        margin-right: 10px;
    }
}

.trexRegExInput-input {
    display: grid;
    gap: 16px;
    grid-template-columns: min-content auto min-content min-content;
    padding: 0.2em 0;

    @include theme-aware('color', $textColorOnSecondary);
    @include theme-aware('background-color', $backgroundColor);
    transition: background-color 0.2s, color 0.2s;

    border: 5px solid transparent;
    border-left: 5px solid transparent;
    border-top: 10px solid transparent;

    .trexRegExInput-inputText {
        margin-left: -14px;
        overflow-y: hidden;
        box-sizing: border-box;
        font-size: 1.5em;
        @include theme-aware('color', $textColorOnSecondary);
        @include theme-aware('background-color', $backgroundColor);
        transition: background-color 0.2s, color 0.2s;
        border: none !important;
        outline: none !important;
        display: inline-block;
    }

    .trexRegExInput-errorIcon {
        width: 25px;
        padding-top: 3px;
        display: inline-block;
        visibility: hidden;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: transparent; /* For some Androids */
    }

    .CodeMirror {
        height: auto;
    }

    .CodeMirror-scroll {
        max-height: 1.6em;
        @include theme-aware('color', $textColorOnSecondary);
        @include theme-aware('background-color', $backgroundColor);
        transition: background-color 0.2s, color 0.2s;

        .CodeMirror-cursor {
            @include theme-aware-important('border-left-color', $textColorOnSecondary);
        }
    }

    .TrexMultiSelectDropdown-contain {
        float: right;
        width: auto;
    }

    .trexRegExInput-flagdropdown {
        margin-right: -10px;
    }
}

@media screen and (min-width: 800px) {
    .trexRegExInput-header-mobileTitle {
        display: none;
    }
}

@media handheld, screen and (max-width: 799px) {
    .trexRegExInput-header-title {
        display: none;
    }
}
