@import '../../scssVariables/variables.module.scss';

.trexDetails-wrapper {
    height: 100%;
    display: grid;
    grid-template-rows: 45px auto;
}

.trexDetails-header {
    @include theme-aware('background-color', $secondaryColor);
    @include theme-aware('color', $textColorOnSecondary);
    font-size: 1.6em;
    transition: background-color 0.2s, color 0.2s;

    padding: 0.2em 30px;
    white-space: nowrap;
}

.trexDiagramOutput-wrapper {
    cursor: grab;
}

.TrexMainPage-splitterWrapper {
    .layout-pane-primary {
        max-height: 70vh;
    }
}


#trexDropdown-select-trexDetails-dummyDataSelection {
    margin-left: 20px;
    width: 150px;
}
