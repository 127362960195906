@import '../../scssVariables/variables.module.scss';

.trexFlavorModal-modal {
    display: flex;
    @include theme-aware('color', $textColorOnSecondary);
    transition: color 0.2s;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    overflow-wrap: break-word;

    .trexFlavorModal-wrapper {
        @include theme-aware('background-color', $backgroundColor);
        border-radius: 10px;
        outline: none;
        text-align: center;
        padding-bottom: 15px;
        margin: 10px 50px;

        .trexFlavorModal-learnMore-link {
            padding-top: 8px;

            a:link {
                @include theme-aware('color', $primaryColorLight);
                transition: color 0.2s;
            }
            a:visited {
                @include theme-aware('color', $primaryColorLight);
                transition: color 0.2s;
            }
        }

        .trexFlavorModal-titlebar {
            display: grid;
            grid-template-columns: auto min-content;
            margin: 0 10px;

            #transition-modal-title {
                padding-left: 30px;
            }
        }

        .trexFlavorModal-flavor-title {
            font-weight: bold;
            text-align: center;
            padding-bottom: 10px;
            a:link {
                @include theme-aware('color', $primaryColorDark);
                transition: color 0.2s;
            }
            a:visited {
                @include theme-aware('color', $primaryColorDark);
                transition: color 0.2s;
            }

            .trexFlavorModal-ref-icon {
                margin-left: 0.2em;
            }
        }

        .trexFlavorModal-flavor-catTitle {
            font-weight: bold;
            padding-top: 10px;
            padding-bottom: 2px;
        }

        .trexFlavorModal-flavor-catContent {
            ul {
                margin: 5px 0px;

                a:link {
                    @include theme-aware('color', $primaryColorDark);
                    transition: color 0.2s;
                }
                a:visited {
                    @include theme-aware('color', $primaryColorDark);
                    transition: color 0.2s;
                }
            }
        }

        #transition-modal-description {
            text-align: left;
            display: flex;
            flex-wrap: wrap;
            max-height: 80vh;
            overflow-x: hidden;
            overflow-y: auto;
            justify-content: center;

            .selected {
                @include theme-aware('border-color', $primaryColor);
                border: 2px;
                border-style: solid;
            }

            .notSupported {
                @include theme-aware('color', $textColorOnSecondaryLight);
            }

            .trexFlavorModal-flavor-wrapper {
                @include theme-aware('background-color', $secondaryColor);
                display: flex;
                flex-direction: column;
                margin: 10px;
                padding: 10px;
                border-radius: 10px;
                width: 300px;
                min-width: 280px;
                max-width: 350px;
                flex: 1;
            }
        }

        .trexFlavorModal-learn-icon {
            padding-left: 5px;
        }
    }
}

@media handheld, screen and (max-width: 610px) {
    .trexFlavorModal-wrapper {
        height: 80vh;
    }
    #transition-modal-description {
        height: 65vh;
    }
}
