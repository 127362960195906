@import './../../scssVariables/variables.module.scss';

.trexSideBar-nav {
    @include theme-aware('color', $textColorOnSecondary);
    @include theme-aware('background-color', $secondaryColorLight);
    transition: background-color 0.2s, color 0.2s;
    position: fixed;
    height: 100vh;
    box-sizing: border-box;
    top: 0;
    left: 0;
    z-index: 100;
}

.trexSideBar-nav-collapsed {
    width: 64px;
}

@media screen and (min-width: 611px) {
    .trexSideBar-nav {
        @include theme-aware('background-color', $secondaryColorLight);
        transition: background-color 0.2s;
    }

    .trexSideBar-nav-extended {
        width: 350px;
        .footer {
            position: absolute;
            margin: 0;
            bottom: 0 !important;
            left: 0;
            text-align: left;
            font-size: 13px;
            width: 350px;
        }

        .version {
            float: right;
            padding-right: 1em;
        }

        .legal {
            padding-left: 1em;
            float: left;

            a:link {
                @include theme-aware('color', $primaryColorDark);
                transition: color 0.2s;
            }
            a:visited {
                @include theme-aware('color', $primaryColorDark);
                transition: color 0.2s;
            }
        }
    }
}

@media handheld, screen and (max-width: 610px) {
    .trexSideBar-nav {
        @include theme-aware('background-color', $secondaryColorLight);
        transition: background-color 0.2s;
        position: absolute;
        height: 64px;
    }

    .trexSideBar-items {
        display: none;
    }

    .trexSideBar-nav-extended {
        width: 100%;
        height: 100vh;

        .trexSideBar-items {
            display: block;
        }

        .footer {
            position: absolute;
            margin: 0;
            bottom: 0 !important;
            left: 0;
            text-align: left;
            font-size: 13px;
            width: 100%;

            .version {
                padding-right: 1em;
                float: right;
            }

            .legal {
                padding-left: 1em;
                float: left;

                a:link {
                    @include theme-aware('color', $primaryColorDark);
                    transition: color 0.2s;
                }
                a:visited {
                    @include theme-aware('color', $primaryColorDark);
                    transition: color 0.2s;
                }
            }
        }
    }
}

.trexSideBar-header {
    cursor: default;
    display: grid;
    grid-template-columns: min-content auto min-content;
    .trexSideBar-heading {
        font-size: 1.7em;
        padding-left: 10px;
        padding-top: 1px;
        line-height: 55px;
        transition: 0.2s;
    }
}
