@import '../../scssVariables/variables.module.scss';

.trexDiagramOutput-wrapper {
    min-height: 250px;
    height: 100%;
    font-family: 'Roboto Mono', 'Ubuntu Mono', monospace, Serif;
    white-space: nowrap;
    .react-flow__controls-button {
        @include theme-aware('background-color', $secondaryColor);
        @include theme-aware('fill', $textColorOnSecondary);
        border: none;
        transition: 0.2s;
    }

    .trexDiagramOutput-error {
        padding: 16px;
        @include theme-aware('color', $textColorOnSecondary);
        transition: color 0.2s;
    }
}

.react-flow {
    @include theme-aware('color', $textColorOnSecondary);
    font-size: 1.6em;
}
