@import './../../scssVariables/variables.module.scss';

.TrexMultiSelectDropdown-contain {
    max-height: 2em;
    min-width: 120px;
    max-width: 300px;
    outline: none !important;

    .MuiInput-underline {
        &:before,
        &:after {
            display: none !important;
            margin-bottom: auto;
        }
    }

    .MuiInputBase-root {
        @include theme-aware-important('color', $textColorOnSecondary);
        transition: 0.2s;
    }

    .MuiSelect-select:focus {
        @include theme-aware-important('background-color', $backgroundColor);
    }

    .MuiSelect-icon {
        @include theme-aware-important('color', $textColorOnSecondary);
        transition: 0.2s;
    }
}
