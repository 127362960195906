@import '../../../scssVariables/variables.module.scss';

.trexSimpleText-wrapper {
    border: 3px solid #b1b1b7;
    padding: 10px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.trexSimpleText-content {
    text-align: center;
    transition: 0.2s;
}
