@import '../../../scssVariables/variables.module.scss';

.trexEndNode-targethandle {
    height: 40px !important;
    width: 40px !important;
    border: none !important;
    @include theme-aware-important('background-color', $primaryColor);
    transition: background-color 0.2s;
}

.trexEndNode-wrapper {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
}