@import '../../scssVariables/variables.module.scss';

.trexMatchOutput-wrapper {
    height: 100%;
}

.trexMatchOutput-header {
    @include theme-aware('background-color', $secondaryColor);
    @include theme-aware('color', $textColorOnSecondary);
    font-size: 1.6em;
    transition: background-color 0.2s, color 0.2s;

    padding: 0.2em 30px;
    white-space: nowrap;
}

.trexMatchOutput-content {
    @include theme-aware('background-color', $backgroundColor);
    @include theme-aware('color', $textColorOnSecondary);
    transition: background-color 0.2s, color 0.2s;
    box-sizing: border-box;
    height: calc(100% - 41px);
    overflow-y: auto;
    border: 5px solid transparent;

    ul {
        list-style: none;
        padding-left: 30px;
        padding-bottom: 15px;
    }
}

.trexMatchOutput-match {
    display: flex;
}

.trexMatchOutput-matchbar {
    float: left;
    margin-bottom: 20px;
    border: 3px solid;
    @include theme-aware('border-color', $primaryColorDark);
    border-radius: 10px;
}

.trexMatchOutput-matchcontent {
    padding-left: 10px;
    margin-left: 10px;
    margin-bottom: 20px;

    ul {
        padding-left: 15px;
    }

    .trexMatchOutput-matchnumber {
        font-weight: bold;
        @include theme-aware('color', $primaryColorDark);
        transition: color 0.2s;
    }

    .trexMatchOutput-matchposition {
        font-weight: bold;
        @include theme-aware('color', $secondaryColor);
        transition: color 0.2s;
        padding-left: 10px;
    }

    .trexMatchOutput-matchtext {
        display: block;
        padding-left: 30px;
        font-family: monospace;
        font-size: 1.4em;
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .trexMatchOutput-groupname {
        font-weight: bold;
        @include theme-aware('color', $primaryColorDark);
        transition: color 0.2s;
    }

    .trexMatchOutput-groupposition {
        font-weight: bold;
        @include theme-aware('color', $secondaryColor);
        transition: color 0.2s;
        padding-left: 10px;
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .trexMatchOutput-grouptext {
        padding-left: 35px;
        display: block;
        font-family: monospace;
        font-size: 1.4em;
    }
}
